import { EMAIL_FOR_SIGN_IN } from '../constants';
import { generateUserDocument } from './user';
import { getAuth } from '../firebase';

export const signIn = async (email, password) => {
  const {auth, signInWithEmailAndPassword} = await getAuth();
  return signInWithEmailAndPassword(auth, email, password);
}

export const signUp = async (userData: { 
  email: string, 
  password: string,
  firstName: string,
  lastName: string,
  gender: string
}) => {
  const { email, password, ...userInfo } = userData;
  const {auth, createUserWithEmailAndPassword} = await getAuth();
  createUserWithEmailAndPassword(auth, email, password)
    .then((res) => generateUserDocument({ ...userInfo, email, uid: res.user.uid }));
}

export const signOutFirebase = async () => {
  const { auth, signOut } = await getAuth();
  signOut(auth)
}

// export const socialMediaSignIn = (provider) => signInWithRedirect(auth, provider);

export const sendSignInLink = async (email: string) => {
  const { auth, sendSignInLinkToEmail } = await getAuth();
  sendSignInLinkToEmail(auth, email, {
    url: window.location.origin,
    handleCodeInApp: true,
  }).then(() => localStorage.setItem(EMAIL_FOR_SIGN_IN, email));
}