import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth } from '../firebase';
import { getUserDocument } from '../services/user';

type AuthContextType = {
  user: {
    uid: string;
    email: string; 
    gender: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    photoURL: string;
  } | null
  loading: boolean
  syncing: boolean
  setUser?: (user) => void
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  syncing: false,
});
export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState(false);

  useEffect(() => {
    let unsubscribe;

    // subscribe to auth observer
    getAuth().then(({ auth, onAuthStateChanged }) => {
      // onAuthStateChanged returns an unsubscribe function
      unsubscribe = onAuthStateChanged(auth, async (newUser) => {
        console.log('auth state change', newUser, auth?.currentUser?.email)
        let uid = newUser.uid;
        if (sessionStorage.getItem('user')) {
          console.log('Moonlighting as user', JSON.parse(sessionStorage.getItem('user')));
          uid = JSON.parse(sessionStorage.getItem('user')).uid;
        }
        if (newUser === null) {
          setUser(null);
        } else {
          setSyncing(true);
          const userDoc = await getUserDocument(uid);
          // console.log(userDoc);
          setUser(userDoc);
          setSyncing(false);
        }
  
        setLoading(false);
      });
    });
     
    // unsubscribe from auth observer on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, syncing, setUser }}>
      { children }
    </AuthContext.Provider>
  );
}
