import PropTypes from "prop-types";
import Head from "next/head";
import { useRouter } from "next/router";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import theme from "../src/theme";
import createEmotionCache from "../src/createEmotionCache";

import "./style.css";
import { AuthProvider } from "../src/contexts/AuthProvider";

import { routes } from "../src/routes";
import NavBar from "../src/components/NavBar";
import Footer from "../src/components/Footer";
import Script from 'next/script'
import { CANONICAL_URL } from "../src/constants";
import { SpeedInsights } from "@vercel/speed-insights/next"

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();

  return (
    <>
      <SpeedInsights />
      <CacheProvider value={emotionCache}>
        <Head>
          <title>TrueDating</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <title>Speed Dating London | Singles Parties | True Dating</title>
          <meta
            name="description"
            content="Explore London's best speed dating with the leading dating site in London. Join our platform to enjoy fantastic London venues for speed Dating London."
          />
          <meta
            name="keywords"
            content="speed Dating, speed Dating London, dating in London, dating site in london, online speed dating, single Parties"
          />
          <link rel="preconnect" href="https://www.googletagmanager.com" crossOrigin="true" />
          <link rel="canonical" href={`${CANONICAL_URL}${router.asPath.split('?')[0]}`} key="canonical" />
          <meta name="robots" content="index, follow" />
        </Head>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthProvider>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              {![
                routes.login,
                routes.signUp,
                routes.passwordReset,
              ].includes(router.pathname) && <NavBar />}
              <Component {...pageProps} />
              {![
                routes.login,
                routes.signUp,
                routes.passwordReset,
              ].includes(router.pathname) && <Footer />}
            </AuthProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
      <Script src="https://www.googletagmanager.com/gtag/js?id=GTM-K4XQH8C" />
      <Script src="https://www.googletagmanager.com/gtag/js?id=AW-672747921" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'GTM-K4XQH8C');
          gtag('config', 'AW-672747921');
        `}
      </Script>
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
